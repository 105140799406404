export enum SliceTypeEnum {
    ACCESS_MAP = 'accessMap',
    ACCORDION = 'accordion',
    BACKGROUND = 'background',
    BANNER_DISCOVER = 'bannerDiscover',
    BANNER_HERO = 'bannerHero',
    BANNER_MINI = 'bannerMini',
    BUS_RESERVATION = 'busReservation',
    CARDS_COLLECTION = 'cardsCollection',
    CHRONOLOGY = 'chronology',
    CITY_MAP = 'cityMap',
    CONTACT_FORM = 'contactForm',
    CREATE_YOUR_ACCOUNT = 'createYourAccount',
    EVENTS_EVENTS_UPCOMING = 'eventsEventsUpcoming',
    EVENTS_QUALIFIER_SEARCH = 'qualifierSearch',
    EVENTS_WORLD_INDEX_RANKING_TABLE = 'eventsWorldIndexRankingTable',
    EVENTS_WORLD_INDEX_TOP = 'eventsWorldIndexTop',
    EVENTS_WORLD_MAP = 'eventsWorldMap',
    EVENTS_WORLD_SERIES_RACE_LIST = 'eventsWorldSeriesRaceList',
    EXPANDABLE_CARDS_COLLECTION = 'expandableCardsCollection',
    INNER_NAV = 'innerNav',
    LATEST_NEWS = 'latestNews',
    LIST_OF_MAG = 'listOfMag',
    LIST_OF_RACES = 'listOfRaces',
    MIXED = 'mixed',
    NEWSLETTER_FORM = 'newsletterForm',
    PARTNERS = 'partners',
    PHOTO_GALLERY = 'photoGallery',
    PICTURE_ONLY = 'pictureOnly',
    PROMOTION_SLIDER = 'promotionSlider',
    QUEST_LIST = 'questList',
    QUEST_SELECTOR = 'questSelector',
    QUOTE = 'quote',
    QUOTE_SLIDER = 'quoteSlider',
    RACE_BANNER = 'raceBanner',
    RACE_PROFILE_MAP_TABLE = 'raceProfileMapTable',
    RACE_STATS = 'raceStats',
    RESULTS = 'results',
    SHUTTLE_BOOKING = 'shuttle_booking',
    SECTION = 'section',
    START_ELITES = 'startElites',
    START_LIST = 'startList',
    STONES_ALLOCATIONS = 'stonesAllocations',
    TITLE_SUBTITLE = 'titleSubtitle',
    VIDEO_ONLY = 'videoOnly',
}
